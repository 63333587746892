/**
 * Not really great practice, but a quick fix to avoid global variables and
 * cyclic dependencies during the ES6 migration.
 */

// Formerly window.currentCandidate
let currentCandidate = {};

export const setCurrentCandidate = (candidate = {}) => {
  currentCandidate = candidate || {};
};

export const getCurrentCandidate = () => Object.freeze({ ...currentCandidate });

// Formerly window.currentOpenTodoId

/** @type {string | undefined} */
let currentOpenTodoId;

export const getCurrentOpenToDoId = () => currentOpenTodoId;

/** @param {string | undefined} id */
export const setCurrentOpenToDoId = (id) => {
  currentOpenTodoId = id;
};

// Formerly window.datepickerRangeChanged

/** @type {boolean} */
let datepickerRangeChanged = false;

/** @param {boolean} value */
export const setDateRangePickerChanged = (value) => {
  datepickerRangeChanged = value;
};

export const getDateRangePickerChanged = () => datepickerRangeChanged;
