// Smooth scrolling using jQuery easing
export const initializeTheme = () => {
  if (window.innerWidth < 768) {
    $("[data-bss-disabled-mobile]")
      .removeClass("animated")
      .removeAttr("data-aos data-bss-hover-animate");
  }

  $(document).ready(() => {
    AOS.init();
    $("[data-bss-tooltip]")
      .tooltip("dispose")
      .tooltip({ html: true, target: "hover" });
  });

  $(window).resize(() => {
    if ($(window).width() < 768) {
      $(".sidebar .collapse").collapse("hide");
      $("#content-wrapper").css("margin-left", "0");
    }
  });

  $("body.fixed-nav .sidebar").on("mousewheel DOMMouseScroll wheel", (e) => {
    if ($(window).width() > 768) {
      const e0 = e.originalEvent;
      const delta = e0.wheelDelta || -e0.detail;
      e.target.scrollTop += (delta < 0 ? 1 : -1) * 30;
      e.preventDefault();
    }
  });

  $(document).on("click", "a.scroll-to-top", (e) => {
    const $anchor = $(e.target);
    $("html, body")
      .stop()
      .animate(
        {
          scrollTop: $($anchor.attr("href")).offset().top,
        },
        1000,
        "easeInOutExpo",
      );
    e.preventDefault();
  });
};
