import { swRequest } from "./sw-request.js";
import { getSessionKey } from "../../assets/js/src/general/websocket.js";

export const getUser = () =>
  swRequest({
    path: "/api/users/get",
    params: {
      sessionKey: getSessionKey(),
      href: window.location.href,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });
