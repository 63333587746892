import { uuidV4 } from "./utils.js";

const { host, pathname } = window.location;

let sessionKey = null;
export const getSessionKey = () => {
  if (sessionKey) return sessionKey;

  sessionKey = sessionStorage.getItem("sessionKey");
  if (!sessionKey) {
    sessionKey = uuidV4();
    sessionStorage.setItem("sessionKey", sessionKey);
  }
  return sessionKey;
};

const WEB_SOCKET_LISTENERS = [];

let WEBSOCKET = null;

const initializeWebsocket = () => {
  if (WEBSOCKET) return;

  WEBSOCKET = new WebSocket(
    `wss://${host}/socket?pathname=${pathname}&sessionKey=${getSessionKey()}`,
  );

  const onClose = () => {
    WEBSOCKET = null;
    initializeWebsocket();
  };

  WEBSOCKET.addEventListener("error", () => {
    WEBSOCKET.close();
  });

  WEBSOCKET.addEventListener("close", () => {
    setTimeout(onClose, 3000);
  });

  WEB_SOCKET_LISTENERS.forEach(({ listener, type }) => {
    WEBSOCKET.addEventListener(type, (event) => {
      if (type === "message") listener(JSON.parse(event.data));
      else listener(event);
    });
  });
};

export const addWebsocketListener = (listener, type = "message") => {
  initializeWebsocket();

  WEB_SOCKET_LISTENERS.push({ listener, type });
  WEBSOCKET.addEventListener(type, (event) => {
    if (type === "message") listener(JSON.parse(event.data));
    else listener(event);
  });
};
