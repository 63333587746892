import { swRequest } from "./sw-request";

export const signOut = (toast) => {
  console.log("Called");
  if (toast) {
    localStorage.setItem("signOutToast", JSON.stringify(toast));
  }
  return swRequest({
    path: "/api/users/sign_out",
  }).then(() => {
    localStorage.removeItem("provider");
    window.location = "/signin";
  });
};
